import React from 'react';
import './PrivacyPolicy.css';
import HeaderLogo from '../assets/icons/logo-header.png';
import RoundLogo from '../assets/icons/logo-round.png';
import FooterLogo from '../assets/icons/logo-footer.png';
import AppStore from '../assets/icons/app-store.png';
import GooglePlayStore from '../assets/icons/google-play-store.png';

export default function PrivacyPolicy() {

    return (
        <div className='background'>
            <div className='header'>
                <div className='header-logo'>
                    <img alt='Header Logo' src={HeaderLogo} className='header-logo-img'/>
                </div>
                <div className='header-main'>
                    <img alt='Round Logo' src={RoundLogo} className='round-logo-img'/>
                    <div className='privacy-policy'>Privacy Policy</div>
                </div>
            </div>
            <div className='descriptions'>
                <div className='subheading'>Privacy Policy</div>
                <div className='description'>This privacy policy applies to the Pawdle app (hereby referred to as "Application") for mobile devices that was created by BeroAI (hereby referred to as "Service Provider") as a Free service. This service is intended for use "AS IS".</div>
                <div className='subheading'>Information Collection and Use</div>
                <div className='description'>
                    The Application collects information when you download and use it. This information may include information such as
                    Your device's Internet Protocol address (e.g. IP address)
                    The pages of the Application that you visit, the time and date of your visit, the time spent on those pages
                    The time spent on the Application
                    The operating system you use on your mobile device
                    The Application collects your device's location, which helps the Service Provider determine your approximate geographical location and make use of in below ways:
                    Geolocation Services: The Service Provider utilizes location data to provide features such as personalized content, relevant recommendations, and location-based services.
                    Analytics and Improvements: Aggregated and anonymized location data helps the Service Provider to analyze user behavior, identify trends, and improve the overall performance and functionality of the Application.
                    For a better experience, while using the Application, the Service Provider may require you to provide us with certain personally identifiable information, including but not limited to Name, Age, Gender, Date of Birth. The information that the Service Provider request will be retained by them and used as described in this privacy policy.
                </div>
                <div className='subheading'>Opt-Out Rights</div>
                <div className='description'>
                    You can stop all collection of information by the Application easily by uninstalling it. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.
                </div>
                <div className='subheading'>Data Retention Policy</div>
                <div className='description'>
                    The Service Provider will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. If you'd like them to delete User Provided Data that you have provided via the Application, please contact them at support@beroai.ca and they will respond in a reasonable time.
                </div>
                <div className='subheading'>Children</div>
                <div className='description'>
                    Service Provider does not use the Application to knowingly solicit data from or market to children under the age of 13.
                    The Application does not address anyone under the age of 13. The Service Provider does not knowingly collect personally identifiable information from children under 13 years of age. In the case the Service Provider discover that a child under 13 has provided personal information, the Service Provider will immediately delete this from their servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact the Service Provider (support@beroai.ca) so that they will be able to take the necessary actions.
                </div>
                <div className='subheading'>Security</div>
                <div className='description'>
                    The Service Provider is concerned about safeguarding the confidentiality of your information. The Service Provider provides physical, electronic, and procedural safeguards to protect information the Service Provider processes and maintains.
                </div>
                <div className='subheading'>Changes</div>
                <div className='description'>
                    This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you of any changes to the Privacy Policy by updating this page with the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.
                    This privacy policy is effective as of 2024-12-08
                </div>
                <div className='subheading'>Your Consent</div>
                <div className='description'>
                    By using the Application, you are consenting to the processing of your information as set forth in this Privacy Policy now and as amended by us.
                </div>
                <div className='subheading'>Contact Us</div>
                <div className='description'>
                    If you have any questions regarding privacy while using the Application, or have questions about the practices, please contact the Service Provider via email at support@beroai.ca.
                </div>
            </div>
            <div className='footer'>
                <div className='footer-content'>
                    <img alt='Footer Logo' src={FooterLogo} className='footer-logo-img'/>
                    <div className='footer-text'>Get AI-powered insights and tools to deepen the bond between you and your pet, without the complexity of traditional pet care solutions.</div>
                    <div className='footer-copy-and-links'>
                        <div className='footer-copy'>© Pawdle AI Inc, 2024. All rights reserved</div>
                        <div className='footer-links'>
                            <img alt='App Store' src={AppStore} className='store-img'/>
                            <img alt='Google Play Store' src={GooglePlayStore} className='store-img'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}